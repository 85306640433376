<!-- 个人中心页面 -->
<template>
  <a-card class="infoCard" title="个人资料">
    <div>
      <a-descriptions bordered :column="2">
        <template #title>
          <div style="float: left">
            <a-upload
              name="avatar"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              accept=".jpg, .jpeg, .png, bmp, .gif"
              @change="handleChange"
            >
              <a-avatar
                :size="80"
                v-if="userInfo.avatar"
                :src="userInfo.avatar"
                title="点击修改头像"
              >
              </a-avatar>
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">上传头像</div>
              </div>
            </a-upload>
          </div>
          <div style="float: left">
            <div style="padding-top: 10px">
              <a-tag color="error">角色：{{ userInfo.roles[0].name }}</a-tag>
            </div>
            <div>
              <a-tag color="warning">姓名：{{ userInfo.realname }}</a-tag>
            </div>
            <div>
              <a-tag color="processing">工号：{{ userInfo.code }}</a-tag>
            </div>
          </div>
        </template>
        <template #extra>
          <a-button type="primary" size="large" @click="doEditUser(userInfo)"
            >更新资料</a-button
          >
        </template>
        <a-descriptions-item label="公司">
          {{ userInfo.unit }}
        </a-descriptions-item>
        <a-descriptions-item label="部门">
          {{ userInfo.depart }}
        </a-descriptions-item>
        <a-descriptions-item label="行业">
          {{ userInfo.profession }}
        </a-descriptions-item>
        <a-descriptions-item label="账号">
          {{ userInfo.username }}
        </a-descriptions-item>
        <a-descriptions-item label="昵称">
          {{ userInfo.nickname }}
        </a-descriptions-item>
        <a-descriptions-item label="手机">
          {{ userInfo.phone }}
        </a-descriptions-item>
        <a-descriptions-item label="状态">
          <a-badge v-if="userInfo.status" status="processing" text="正常" />
          <a-badge v-else status="error" text="禁用" />
        </a-descriptions-item>
        <a-descriptions-item label="个人签名">
          {{ userInfo.memo }}
        </a-descriptions-item>
      </a-descriptions>
    </div>
  </a-card>
  <div>
    <UserForm
      :modalVisible="modalVisible"
      :editData="editData"
      :isCreate="1"
      @handleModalVisible="handleModalVisible"
      @saveCallBack="saveCallBack"
    />
  </div>
</template>

<script>
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import { message } from "ant-design-vue";
import UserForm from "../../user/userlist/components/UserForm.vue";
import { uploadUserAvatar, updateUserAvatar, getCurrentUser } from "@/api/user";
export default {
  name: "UserCenter",
  components: {
    UserForm,
    PlusOutlined,
    LoadingOutlined,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      editData: {
        id: "",
        realname: "",
        code: "",
        username: "",
        nickname: "",
        phone: "",
        unit: "",
        profession: "",
        depart: "",
        memo: "",
      },
      modalVisible: false,
      userInfo: JSON.parse(sessionStorage.getItem("userinfo")),
    });
    onMounted(() => {});
    const doEditUser = (record) => {
      handleModalVisible(true);
      state.editData = {
        id: record.id,
        realname: record.realname,
        code: record.code,
        username: record.username,
        nickname: record.nickname,
        phone: record.phone,
        unit: record.unit,
        profession: record.profession,
        depart: record.depart,
        memo: record.memo,
      };
    };
    const handleModalVisible = (flag) => {
      state.modalVisible = flag;
    };
    const saveCallBack = (code) => {
      if (code == 200) {
        state.userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
      }
    };
    function getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    }
    const handleChange = (info) => {
      if (info.file.status === "uploading") {
        state.loading = true;
        return;
      }

      if (info.file.status === "done") {
        getBase64(info.file.originFileObj, (base64Url) => {
          state.userInfo.avatar = base64Url;
          state.loading = false;
        });

        const formData = new FormData();
        formData.append("avatar", info.file.originFileObj);

        uploadUserAvatar(formData).then((resp) => {
          if (resp.obj) {
            updateUserAvatar({
              uid: state.userInfo.id,
              avatar: resp.obj.fileName,
            }).then(() => {
              //获取用户信息
              getCurrentUser().then((data) => {
                if (data) {
                  sessionStorage.removeItem("userinfo");
                  //sessionStorage.setItem("userinfo", JSON.stringify(data));
                  proxy.resetSetItem("userinfo", JSON.stringify(data));
                  state.userInfo = data;
                }
              });
            });
          }
        });
      }

      if (info.file.status === "error") {
        state.loading = false;
        message.error("upload error");
      }
    };

    const beforeUpload = (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";

      if (!isJpgOrPng) {
        message.error("You can only upload JPG file!");
      }

      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
      }

      return isJpgOrPng && isLt2M;
    };
    const refData = toRefs(state);
    return {
      ...refData,
      doEditUser,
      handleModalVisible,
      saveCallBack,
      handleChange,
      beforeUpload,
    };
  },
};
</script>
<style lang='scss'>
.avatar-uploader > .ant-upload {
  width: 80px;
  height: 80px;
}

.ant-upload.ant-upload-select-picture-card {
  margin: 0;
  padding: 0;
  background-color: white;
  border: 1px solid white;
}
.infoCard {
  width: 100%;
  height: 100%;
  margin: auto;
  //margin-top: 5%;
  border: 1px solid rgb(161, 205, 236);
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(7, 17, 27, 0.25);
}
.ant-card-head {
  border-bottom: 1px solid rgb(161, 205, 236);
  border-radius: 8px 8px 0 0;
  background: rgb(235, 242, 252);
}
.ant-descriptions-header {
  margin-bottom: 5px;
}
.ant-descriptions-item-content,
.ant-badge-status-text {
  font-weight: 500;
  color: rgb(81, 92, 245);
}
</style>